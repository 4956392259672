export const SET_AUTHENTICATED = "SET_AUTHENTICATED"
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED"
export const SET_USER = "SET_USER"
export const LOADING_USER = "LOADING_USER"

export const SET_ERRORS = "SET_ERRORS"
export const LOADING_UI = "LOADING_UI"
export const CLEAR_ERRORS = "CLEAR_ERRORS"

export const SET_SCRIBBLES = "SET_SCRIBBLES"
export const SET_SCRIBBLE = "SET_SCRIBBLE"
export const LIKE_SCRIBBLE = "LIKE_SCRIBBLE"
export const UNLIKE_SCRIBBLE = "UNLIKE_SCRIBBLE"
export const LOADING_DATA = "LOADING_DATA"

export const DELETE_SCRIBBLE = "DELETE_SCRIBBLE"
export const POST_SCRIBBLE = "POST_SCRIBBLE"
export const STOP_LOADING_UI = "STOP_LOADING_UI"
export const SUBMIT_COMMENT = "SUBMIT_COMMENT"